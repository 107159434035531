<template>
  <div class="MonitorViewOptions">
    <div ref="link">
      <base-button
        class="button"
        icon="IconButtonArrowDownGrey"
        :icon-width="12"
        :icon-height="7"
      >
        View Options
      </base-button>
    </div>

    <div ref="dropdown" class="dropdown px-0">
      <div class="title mt-2 ml-1">Show</div>
      <node v-for="option in showOptions"
            :key="option.id"
            class="view-options-dropdown mb-0"
            :node="option"
            @toggle="selectFilter(option, $event)"
      />

      <div v-if="!custom">
        <div class="title mt-2 ml-1 mb-1">Sort By</div>
        <node v-for="option in sortOptions"
              :key="option.id"
              :node="option"
              class="view-options-dropdown mb-0"
              @toggle="selectSort(option, $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import tippy from 'tippy.js'
import Node from '@/components/Common/Filters/FilterLocation/FilterLocationNode.vue'

export default {
  components: {
    Node
  },

  props: {
    user: {
      required: true
    }
  },

  data () {
    return {
      tippyInstance: null,
      open: false,
      showOptions: [
        { id: 11, title: 'Everything', value: 'everything', selected: false },
        { id: 12, title: 'Online', value: 'online', selected: false },
        { id: 13, title: 'Offline', value: 'offline', selected: false },
        { id: 14, title: 'Paused', value: 'paused', selected: false },
        { id: 15, title: 'Custom', value: 'custom', selected: false }
      ],
      sortOptions: [
        { id: 1, title: 'Newest First', value: { sort: 'created_at', sortDirection: 'desc' }, selected: false },
        { id: 2, title: 'Oldest First', value: { sort: 'created_at', sortDirection: 'asc' }, selected: false },
        { id: 3, title: 'Name (A-Z)', value: { sort: 'name', sortDirection: 'asc' }, selected: false },
        { id: 4, title: 'Name (Z-A)', value: { sort: 'name', sortDirection: 'desc' }, selected: false },
        { id: 5, title: 'SSL Expires', value: { sort: 'ssl_certificate_state', sortDirection: 'asc' }, selected: false },
        { id: 6, title: 'Uptime', value: { sort: 'uptime', sortDirection: 'desc' }, selected: false }
      ],
      savedSortOptions: this.user.preferences.MonitorSortFilter ? JSON.parse(this.user.preferences.MonitorSortFilter) : null,
      savedShowOptions: this.user.preferences.MonitorShowFilter ? JSON.parse(this.user.preferences.MonitorShowFilter) : null,
      custom: false
    }
  },

  created () {
    if (this.savedShowOptions) {
      this.showOptions.forEach((element) => {
        if (this.savedShowOptions.status && this.savedShowOptions.status.includes(element.value)) {
          element.selected = true
        }
        if (element.value === 'paused' && parseInt(this.savedShowOptions.is_running) === 0) {
          element.selected = true
        }
      })
    }
    if (this.savedSortOptions) {
      this.sortOptions.forEach((element) => {
        if (element.value.sort === this.savedSortOptions.sort && element.value.sortDirection === this.savedSortOptions.sortDirection) {
          element.selected = true
        }
      })
    }
  },

  mounted () {
    this.setTippy()
  },

  methods: {
    selectFilter (option, selected) {
      this.showOptions = this.showOptions.map((item) => ({
        ...item,
        selected: item.id === option.id ? selected : false
      }))

      // if unchecked, select everything
      if (!selected) {
        this.showOptions[0].selected = true
        option = this.showOptions[0]
      }

      if (option.value === 'paused' && selected) {
        this.$emit('updateRunning', true)
        this.$emit('updateFilters', [])
      } else {
        this.$emit('updateRunning', false)
        this.$emit('updateFilters', [option.value])
      }

      this.custom = option.value === 'custom'
      this.tippyInstance.hide()
    },

    selectSort (option, selected) {
      this.sortOptions = this.sortOptions.map((item) => ({
        ...item,
        selected: item.id === option.id ? selected : false
      }))
      this.$emit('updateSort', option.value)
    },

    setTippy () {
      this.tippyInstance = tippy(this.$refs.link, {
        content: this.$refs.dropdown,
        interactive: true,
        theme: 'light-border',
        animation: 'shift-away-subtle',
        placement: 'left-start',
        arrow: true,
        inertia: true,
        trigger: 'click',
        maxWidth: 'none',

        onShow: () => {
          this.open = true
        },

        onHide: () => {
          this.open = false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.MonitorViewOptions {
  display: flex;
  align-items: center;
  padding: 15px 0;

  .button {
    display: flex;
    align-items: center;
    padding: 10px 16px;
    background-color: white;
    color: #333333;
    border: 1px solid #E9EBEC;
    box-shadow: 0px 2px 4px rgba(25, 34, 29, 0.05);
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0.005em;

    &:hover {
      background-color: white !important;
      color: #333333;
    }
  }

  .view-options-dropdown {
    padding: 9px 7px;

    ::v-deep {
      .flag {
        display: none;
      }

      .title {
        margin-left: 0;
        font-size: 15px;
        font-weight: 500;
      }

      .ip {
        display: none;
      }

      .base-checkbox {
        width: 100%;

        & .checkmark {
          border: none;
          background-color: transparent;
        }
      }

      .checkmark {
        &:after {
          padding: 2px 3px;
          color: map-get($colors, purple-1);
        }
      }
    }

    &:hover {
      width: 100%;
      background-color: map-get($colors, purple-1);
      color: white;
      border-radius: 4px;

      ::v-deep {
        .checkmark {
          &:after {
            padding: 2px 3px;
            color: white;
          }
        }
      }
    }
  }

  .dropdown {
    width: 180px;

    .title {
      margin-bottom: 14px;
      font-size: 12px;
      font-weight: 700;
      color: #BDBDBD;
      text-transform: uppercase;
    }
  }
}
</style>
